
import { useIsContentPage } from '../hooks/useIsContentPage';
import './Footer.css';

export const Footer = () => {
  return useIsContentPage() ? <div className="footer">
    Copyright © 2022 Playco. All rights reserved
    <div className='footer-links'>
      <a className='footer-link' href='https://www.play.co/tos'>Terms of Service</a>
      <a className='footer-link' href='https://www.play.co/privacy'>Privacy Notice</a>
    </div>
  </div> : null;
}