
import ImgMichaelCarter from '../assets/img/michael-carter.png';
import ImgJustinWaldron from '../assets/img/justin-waldron.png';
import { Founder } from '../components/Founder';

import './Founders.css';

export const Founders = () => {
    return <div className="main-content">
      <h2>_03</h2>
      <h2>Meet the</h2>
      <h1>Founders</h1>
      <div className="founders">
        <Founder name="Michael Carter"
          img={ ImgMichaelCarter }
          handle="michaelmxcarter"
          desc="Some say he created the WebSocket protocol just to share memes faster and also to revolutionize instant games like the one people using to view the Storyverse right now."
          />
        <Founder name="Justin Waldron"
          img={ ImgJustinWaldron }
          handle="jtwald"
          desc="Product instigator, builder. Selling JPEGs since 2007. Co-Founder of Playco &amp; Zynga, advisor to Dapper Labs (CryptoKitties/NBA Top Shot), Immutable X and Decentraland."
          />
      </div>
    </div>
};
