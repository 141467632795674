import React, { ReactNode, useEffect } from 'react';
import { Route, Routes } from 'react-router';
import { BrowserRouter } from 'react-router-dom';
import './App.css';
import { Announcement } from './pages/Announcement';
import { Faq } from './pages/Faq';
import { Founders } from './pages/Founders';
import { Header } from './components/Header';
import { Footer } from './components/Footer';
import { Contact } from './pages/Contact';
import { Background } from './components/Background';

export const WhitepaperURL = 'https://whitepaper.storyverse.xyz';

const ContentPage = ({children}: {children: ReactNode}) => {
  return <div className="App">
    <Background />
    <div className="App">
      <Header />
        { children }
      <Footer />
    </div>
  </div>
}

const GoTo = ({to}: {to: string}) => {
  useEffect(() => {
    window.location.href = to;
  }, [to]);
  return null;
}

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={ <><Background /><Announcement /></> } />
        <Route path="/founders" element={ <ContentPage><Founders /></ContentPage>} />
        <Route path="/whitepaper" element={ <GoTo to={ WhitepaperURL } /> } />
        <Route path="/faq" element={ <ContentPage><Faq /></ContentPage>} />
        <Route path="/contact" element={ <ContentPage><Contact /></ContentPage> } />
        <Route path="*" element={ <ContentPage><h2>Error 404: Not Found</h2></ContentPage> } />
      </Routes>
    </BrowserRouter>
  );
}

export default App;

