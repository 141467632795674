
import { NavLink } from 'react-router-dom';

import './Header.css';

import ImgLogoNoText from '../assets/img/logo.png';
import ImgLogoText from '../assets/img/logo-2.png';

import ImgDiscord from '../assets/svg/discord.svg';
import ImgTwitter from '../assets/svg/twitter.svg';
import { useIsContentPage } from '../hooks/useIsContentPage';
import { WhitepaperURL } from '../App';

type HeaderLinkProps = {
  to: string,
  name: string
};

const HeaderLink = ({to, name}: HeaderLinkProps) => {
  return <NavLink to={ to } className={
    ({isActive}) => 'header-link '+((isActive && 'active') || '') }>{ name }</NavLink>
}

type SocialLinkProps = {
  platform: string,
  name: string,
  url: string,
};

const SocialLink = ({platform, name, url} : SocialLinkProps) => {
  return <a className="social-link" href={ url } target='_blank' rel='noreferrer'>
    <img src={ platform } alt={ name }/>
  </a>
}

export const Header = () => {
  return useIsContentPage() ? <div className="header-container">
    <div>
      <img className='img-logo-text' src={ ImgLogoText } alt='Storyverse'/>
      <img className='img-logo-notext' src={ ImgLogoNoText } alt='Storyverse'/>
    </div>
    <div className="header-links">
      <HeaderLink to="/" name="Announcement" />
      <a className="header-link" href={ WhitepaperURL }>Whitepaper</a>
      <HeaderLink to="/founders" name="Founders" />
      <HeaderLink to="/faq" name="FAQ" />
      <HeaderLink to="/contact" name="Contact" />
    </div>
    <div className="social-links">
      <SocialLink platform={ ImgDiscord } name='Discord' url="https://discord.gg/storyverse" />
      <SocialLink platform={ ImgTwitter } name='Twitter' url="https://twitter.com/storyverse_xyz" />
    </div>
  </div> : null;
}