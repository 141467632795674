export const Faq = () => {
    return <div className='main-content'>
<h2>_04</h2>
<h1>Frequently asked questions</h1>
<h2>What is the Storyverse?</h2>
<p>
  The <em>Storyverse</em> is a user-generated, no-code game publishing platform.  It fosters community-based character development at scale and unlocks a new generation of games for people to play.  The platform puts game publishing tools into the hands of PFP owners who want to bring their characters to life.
</p>
<h2>What are the main digital assets used in the Storyverse?</h2>
<p>
  There are two primary digital assets in the Storyverse: INK and PLOTs.
</p>
<h2>What is a Story PLOT?</h2>
<p>
  PLOTs are non-fungible, transferable, scarce digital assets (NFT as defined by EIP-721) stored in an Ethereum smart contract.  PLOT ownership is immutably stamped on a blockchain-based ledger, giving the community custody over these scarce assets and control over their collective self-expression. People who own a PLOT can create and publish a story that can be consumed in any browser on the web.
</p>
<h2>What is INK?</h2>
<p>
  INK is built on the Ethereum blockchain and is the main utility token and the currency of the Storyverse's ecosystem. It is also a governance token.
</p>
<h2>What makes each PLOT unique?</h2>
<p>
  The unique location of a PLOT on the X-axis is determined at minting and cannot be moved from one coordinate of the world map to the other.  Discovery, adjacency, and traversability will impact the value of a location.
</p>
<h2>How will creators be able to publish their stories?</h2>
<p>
  Storyverse content creators will use no-code game publishing tools, including rigged and animated characters, text composers, a choice engine, and other features that allow users greater options for self-expression.
</p>
<h2>What value does the Storyverse deliver to PFP communities:</h2>
<ul>
  <li>The decentralized, community-owned economy aligns incentives across the ecosystem</li>
  <li>Publishing tools facilitate new modes of community expression</li>
  <li>A codified licensing protocol reduces ambiguity and helps unlock economic opportunity</li>
  <li>DAO-based governance empowers community members to determine project direction</li>
  <li>New revenue opportunities help support creators</li>
</ul>
<h2>How can I learn more about the Storyverse?</h2>
<p>
  Join the official Storyverse Discord at <a target='_blank' rel='noreferrer' href='https://discord.gg/storyverse'>https://discord.gg/storyverse</a><br/>
  You can also get more information about the Storyverse by following the official Storyverse Twitter account <a target='_blank' rel='noreferrer' href='https://twitter.com/storyverse_xyz'>@storyverse_xyz</a>
</p>
    </div>
};
